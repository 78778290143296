html, body{
	font-size:16px;
	color: #dfdfdf;
	background-color: #0f172a; 
}

body {
	margin: 0;
	padding: 0;
}

a {
  color: #00d1b2;
}

a:hover {
  color: #00d1b2;
}

section{
	padding-top: 3rem;
	padding-bottom: 3rem;

}

.section-1 {
	background: #0f172a;
	margin-top: 50px;
}

.section-2 {
	background-color: #052D3D;
	border-radius: 25px;
	margin: 10px
}

.section-3 {
	margin-top: 50px;
	background-color: #0E282E;
}

.section-4 {
	background-color: #063647;
	padding: 0.2rem;
}


a.nav-item {
	color: #dfdfdf;
}

a.nav-item:hover {
	color: #fff;
}

.avatar{
	height:200px;
	border-radius: 50%;
}

.intro-description{
	padding-top: 1rem;
}

.social-container img{
	max-width:100%;
	max-height:175px;
	margin-bottom:15px;
}

.project-figure {
	margin: 0 auto 1rem auto;
	max-height: 256px;
	max-width: 256px;
}

.project-text {
	text-align: justify;
}

.box {
	background-color: transparent;
	border: 2px solid rgba(15,23,42,255);
}

.title {
	color: #cfd1d4;
	text-align: left;
	margin-bottom: 2rem;
}

.intro-title {
	font-size: 50px;
	margin-bottom: 10px;
	font-weight: 650;
	color:#cfd1d4;
}

.intro-title-smaller {
	font-size: 35px;
	margin-bottom: 10px;
	font-weight: 650;
	margin-top: 20px;
	color:#cfd1d4;
}

.intro {
	text-align: left;
	margin: 0 auto;
}

.void-background {
	background-color: transparent; 
}

.wrapper {
	margin: 0 auto;
	max-width: 60%;
}

.nav {
	background-color: rgba(255, 255, 255, 0.1); /* White-transparent background */
	border: 1px solid rgb(70, 70, 70); /* White border */
	border-radius: 10px; /* Rounded corners */
	position: fixed; /* Keep navbar fixed on top */
	top: 0; /* Stick to the top */
	left: 0; /* Stick to the left */
	right: 0; /* Stick to the right */
	z-index: 1000; /* Ensure it stays on top of other elements */
	backdrop-filter: blur(10px);
	max-width: 65%;
	margin-top: 20px;
  }


  .tech-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(4, 1fr);
	grid-gap: 10px;
	padding-top: 25px;
  }
  
  .tech-item {
	padding: 20px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.1); /* White-transparent background */
	border: 1px solid rgb(70, 70, 70); /* White border */
	border-radius: 25px;
	overflow: hidden;
	transition: all 0.3s ease;
  }

  .tech-item:hover {
	filter: brightness(1.2);
  }
  
  .tech-item::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 100%;
	filter: blur(30px);
	opacity: 0;
	transform: scale(0) translate(-50%, -50%);
	transition: all 0.3s ease;
  }
  
  .tech-item:hover::before {
	opacity: 1;
	transform: scale(1) translate(-50%, -50%);
  }

  /*  Projects css */

  .projects-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 7.5px;
	padding: 5px;
  }

  .fix-div-aos-pos {
	margin-top: -100px;
  }
  
  .project {
	background-color: #1b2637;
	border-radius: 5px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 0.3s ease; /* Add transition property */
  }

  .project:hover {
	background-color: #313848;
  }
  
  .project-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
  }
  
  .project-title {
	margin: 0;
	font-size: 18px;
  }
  
  .project-description {
	text-align: center;
	margin: 10px 0;
  }
  
  .project-stats {
	display: flex;
	justify-content: space-between;
	width: 100%;
  }

/* Overlapping Title Effect */
.title-container {
  position: relative;
  display: inline-block;
}


.background-title {
	position: absolute;
	color: #363c4c;
	font-size: 61px;
	text-align: center;
	font-weight: 650;
	margin-bottom: 20px;
	z-index: 0;
	bottom: -10px;
	left: -15px; /* Adjust the horizontal position */
  }

  /* Footer */

  .footer-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 25px;
	margin-bottom: 5px;
  }
  
  .line {
	flex-grow: 1;
	border-bottom: 1px solid #2a3041;
	margin: 0 15px;
  }
  
  .text {
	white-space: nowrap;
	color: #79879b;
	font-size: 35px;
	font-weight: 650;
  }
  
  .sub-footer-container {
	display: flex;
	justify-content: space-between;
	padding-left: 20px;
	padding-right:20px;
	color: #8c929b;
	margin-bottom: 75px;
  }
  /*       =-------------------=                 */

.intro-title-smaller {
	position: relative;
	font-size: 35px;
	margin-bottom: 10px;
	font-weight: 650;
	margin-top: 20px;
	color:#cfd1d4;
	z-index: 1;
}

/* NAV  */

.nav-right {
	padding-right: 15px;
}

.nav-item {
	margin-right: 10px;
}
  
@media screen and (max-width: 768px) {
	.nav-menu {
		background-color: #063647;
	}

	.nav-toggle:hover {
		background-color: transparent;
	}

	.nav-toggle span {
		background-color: #dfdfdf;
	}

	.nav-toggle span:hover {
		background-color: #fff;
	}

}

.project-link {
	text-decoration: none;
	color: inherit;
	display: block;
  }
  
  .project-link:hover .project {
	background-color: #313848;
	color: #cfd1d4;
  }
