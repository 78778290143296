.discord-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: -20px;
  }
  
  .avatar-container {
    position: relative;
    width: 50px;
    height: 50px;
  }
  
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .status-light {
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #0f172a;
  }
  
  .status-light.online {
    background-color: #43b581;
  }
  
  .status-light.offline {
    background-color: #747f8d;
  }

  .status-light.busy {
    background-color: #f04747;
  }

  .status-light.away {
    background-color: #faa61a;
  }
  
  .discord-info {
    display: flex;
    flex-direction: column;
  }
  
  .discord-username {
    margin: 0;
    font-size: 18px;
    color: #b6b8bf;
  }
  
  .discord-discriminator {
    font-size: x-small;
    font-weight: 500;
    color: #848690;
    margin-top: -5px;
  }